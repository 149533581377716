<template>
  <div>
    <div class="flex flex-row justify-between items-center">
      <h1 class="font-medium text-lg mb-20 px-16 sm:px-0">Users overview</h1>
      <el-button
        type="primary"
        class="whitespace-no-wrap ml-auto sm:ml-24 order-1 sm:order-2 mb-20 sm:mb-0 mr-16 sm:mr-0"
        @click="$router.push({ name: 'AdminUserCreate' })"
      >
        Create User
      </el-button>
    </div>

    <users-list :items="usersData" class="order-2 sm:order-1" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserService from '@/services/user.service'

const UsersList = () => import('@/components/admin-users/UsersList')
const ElButton = () => import('@/components/shared/ELButton')

export default {
  name: 'UsersPage',

  components: { UsersList, ElButton },

  data () {
    return {
      usersData: []
    }
  },

  created () {
    this.firstLoading()
  },

  methods: {
    ...mapActions(['loading']),

    firstLoading () {
      UserService.getAdminUsers()
        .then(res => {
          this.usersData = res
          this.loading(false)
        })
    }
  }
}
</script>
